<template>
  <div
    v-loading="loading"
    class="replay-wapper"
    element-loading-text="正在进入直播间，请稍等"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <err-page-mask-broad-cast
      v-if="errMaskContent"
      :msg="errMaskContent"
    />
    <!--  mp4转录会启用白板  -->
    <white-board-replay
      v-if="(roomInfo.needWhiteBoard
        && !roomInfo.publishStatus !==1)"
      :room-info="roomInfo"
    />
    <video-replay
      v-else
      :room-info="roomInfo"
    />
    <div class="info-group">
      <div v-if="roomInfo.guests && roomInfo.guests.length">
        <div class="info-title">
          Live嘉宾
        </div>
        <live-guest
          :room-id="roomId"
          :guest="roomInfo.guests"
          from-view="replay"
        />
      </div>
      <div v-if="roomInfo.lastWatcher && roomInfo.lastWatcher.length">
        <div class="info-title">
          近期观看的用户
        </div>
        <div class="last-group">
          <template v-for="(item, index) in roomInfo.lastWatcher">
            <el-popover
              :key="index"
              placement="bottom"
              width="270"
              :visible-arrow="false"
              :value="item.showLastPop || false"
              trigger="manual"
              class="guest-popover"
            >
              <option-user
                :user="item"
                :card-info="cardInfo"
                from-view="replay"
                :loading="opUserLoading"
                @updateCardInfo="handleUpdateCardInfo"
              />
              <div
                slot="reference"
                v-sensor:click="{
                  $element_id: 'live_to_personalpage',
                  consumer_id: profile.uid,
                  room_id: roomId,
                }"
                class="last"
                @click="handlerShowPopover(item, index)"
              >
                <logo-panel
                  :info="item"
                  class="avatar"
                />
                <div class="last-name">
                  {{ item.name || '新用户' }}
                </div>
              </div>
            </el-popover>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import WhiteBoardReplay from '@/views/replay/components/WhiteBoardReplay';
import VideoReplay from '@/views/replay/components/VideoReplay';
import { getLiveRoom, joinLeaveRoom } from '@/services/room';
import { getUserCardInfo } from '@/services/user';
import LiveGuest from '@/views/components/LiveGuest';
import LogoPanel from '@/views/components/LogoPanel';
import ErrPageMaskBroadCast from '@/views/components/ErrPageMaskBroadCast';

export default {
  name: 'IndexDouble',
  components: {
    WhiteBoardReplay,
    VideoReplay,
    ErrPageMaskBroadCast,
    LiveGuest,
    LogoPanel,
    OptionUser: () => import('@/views/components/OptionUser'),
  },
  data() {
    return {
      loading: true,
      roomId: this.$route.params.id,
      roomInfo: {},
      errMaskContent: '',
      currPop: null,
      opUserLoading: false,
      upDate: false,
      cardInfo: {},
    };
  },

  computed: {
    ...mapState('user', ['isLogin', 'profile']),
  },
  async mounted() {
    if (!this.isLogin) {
      this.showLoginDialog();
    }
    await this.getLiveInfo();
    if (this.errMaskContent) {
      return;
    }
    const replayWapper = document.querySelector('.replay-wapper');
    if (replayWapper) {
      replayWapper.addEventListener('click', this.handleUserPopVisible);
    }
    if (this.roomInfo.accessed) {
      await this.joinRoom();
    }
  },
  beforeDestroy() {
    const replayWapper = document.querySelector('.replay-wapper');
    if (replayWapper) {
      replayWapper.removeEventListener('click', this.handleUserPopVisible);
    }
  },
  methods: {
    ...mapActions('login', ['showLoginDialog']),
    handlerShowPopover(user, index) {
      // event.stopPropagation();
      if (this.roomInfo.lastWatcher[index].showLastPop) {
        this.roomInfo.lastWatcher[index].showLastPop = false;
        return;
      }
      if (this.currPop !== null) {
        this.roomInfo.lastWatcher[this.currPop].showLastPop = false;
      }
      this.currPop = index;
      const { uid } = user;
      this.getCardInfo(uid);
    },
    async handleUpdateCardInfo(uid) {
      this.upDate = true;
      await this.getCardInfo(uid);
      this.upDate = false;
    },
    async getCardInfo(id) {
      this.opUserLoading = true;
      try {
        const cardInfo = await getUserCardInfo(id);
        this.cardInfo = {
          ...cardInfo,
          uid: id,
        };
        if (this.upDate) return;
        this.roomInfo.lastWatcher[this.currPop].showLastPop = true;
      } catch (error) {
        // console.log(error)
      } finally {
        this.opUserLoading = false;
      }
    },
    handleUserPopVisible() {
      if (this.currPop !== null) {
        this.roomInfo.lastWatcher[this.currPop].showLastPop = false;
        this.$forceUpdate();
      }
    },
    async getLiveInfo() {
      try {
        this.roomInfo = await getLiveRoom(this.roomId);
        this.roomUUID = this.roomInfo.liveId;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.errMaskContent = err;
      }
    },
    // 加入房间
    async joinRoom() {
      await joinLeaveRoom({
        roomId: this.roomId,
        status: 1, // 1:加入 2: 离开
      });
      await this.getLiveInfo();
    },
  },
};
</script>

<style scoped lang="scss">
@import './index';
</style>
<style lang="scss">
@import './globeIndex';
</style>
