<template>
  <div
    v-if="Object.keys(roomInfo).length"
    class="head-group"
  >
    <div class="replay-info">
      <live-mask-broad-cast
        v-if="!roomInfo.accessed"
        :room-id="roomId"
        from-view="replay"
        :refuse-code="roomInfo.accessCode"
        :refuse-reason="roomInfo.accessReason"
      />
      <div class="replay-play">
        <img
          v-if="
            (roomInfo.mp4RecordUrl && roomInfo.publishStatus === 1 && initPlay) //mp4初始状态
              ||
              (roomInfo.resourceConvertStatus === 0 && roomInfo.mp3VideoUrl) //mp3永远显示图片
          "
          class="head-image"
          :src="roomInfo.posterImgUrl"
        >
        <div
          v-loading="handleRate && roomInfo.accessed"
          class="overall-box"
          element-loading-text="加载中"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div
            class="player-out-box"
          >
            <div
              v-if="phase==='playing'"
              id="white-player"
              class="white-player"
              @click="handleStop"
            />
            <div
              v-show="phase!=='playing' && !handleRate && duration"
              class="player-mask"
              @click="handlePlay"
            >
              <img
                src="@/assets/img/play.svg"
                class="bofang-btn"
              >
            </div>
          </div>
          <video
            v-if="roomInfo.mp4RecordUrl && roomInfo.publishStatus === 1"
            ref="rePlayer"
            class="replay-container"
            width="800"
            height="450"
            @canplay="getDuration"
          >
            <source
              :src="playUrl"
              type="audio/mpeg"
            >
          </video>

          <audio
            v-if="(roomInfo.resourceConvertStatus !== 1 || roomInfo.publishStatus !== 1) && playUrl"
            ref="rePlayer"
            class="replay-container"
            @canplay="getDuration"
          >
            <source
              :src="playUrl"
              type="audio/mpeg"
            >
          </audio>
        </div>
        <div class="replay-text">
          <i class="iconfont icon-huifang player-huifang" />
          <span>直播回放</span>
        </div>
        <div
          v-if="roomInfo.accessed"
          class="player-content"
        >
          <span
            v-if="initPlay || phase !== 'playing'"
            class="player-play"
            @click="handlePlay"
          >
            <i class="iconfont icon-v2_play2 player-btn" />
          </span>
          <span
            v-else
            class="player-stop"
            @click="handleStop"
          >
            <i class="iconfont icon-zanting1 player-btn" />
          </span>
          <span class="player-time">{{ progressTime | handleDuration }}</span>/
          <span class="player-total-time ">{{ duration | handleDuration }}</span>
          <slider
            v-model="progress"
            :dots="roomInfo.resourceConvertStatus === 1 ? [] : roomInfo.dots"
            :duration="duration"
            :show-tooltip="false"
            class="player-progress"
            @change="handleProgress"
            @rateMovEnd="handleMovEnd"
          />
          <el-popover
            placement="top"
            width="35"
            trigger="hover"
            :visible-arrow="false"
            :close-delay="10"
            popper-class="volume-popover"
            @show="handlePlayerStyle('show')"
            @hide="handlePlayerStyle('hide')"
          >
            <span class="volume-text">{{ volumeProgress }}%</span>
            <el-slider
              v-model="volumeProgress"
              vertical
              height="90px"
              :show-tooltip="false"
            />

            <span
              slot="reference"
            >
              <span class="option-volume">
                <i class="iconfont icon-v2_voice2 volume-icon" />
              </span>
            </span>
          </el-popover>
        </div>
      </div>
      <div class="title-group">
        <div
          v-if="roomInfo.experienceStatus"
          class="live-experience"
        >
          体验
        </div>
        <div class="title">
          {{ roomInfo.liveTheme }}
          <span class="title-type">{{ roomInfo.liveType | filterLiveType }}</span>
        </div>
        <el-popover
          v-if="!roomInfo.experienceStatus"
          v-sensor:click="{
            $element_id: 'live_share',
            consumer_id: profile.uid,
            room_id: roomId,
          }"
          placement="bottom"
          trigger="click"
          popper-class="share-popover"
          :visible-arrow="false"
          effect="dark"
        >
          <share
            :live-link="liveLink"
            :code-link="roomInfo.codeUrl"
          />
          <div
            slot="reference"
            class="share"
          >
            <el-tooltip
              effect="dark"
              placement="bottom"
              content="分享"
              :visible-arrow="false"
              popper-class="share-tooltip"
            >
              <i class="iconfont icon-fenxiangbeifen" />
            </el-tooltip>
          </div>
        </el-popover>
        <accusal
          v-if="isLogin && roomInfo.accessed"
          :room-id="roomId"
          :room-name="roomInfo.liveTheme"
        />
      </div>
      <div class="head-book">
        <span>
          <i class="iconfont icon-time" />
          {{ roomInfo.startTime | filterTime }}
        </span>
        <span class="open-time">
          <i class="iconfont icon-v_hot" />
          {{ roomInfo.watchNumber || 0 }} 人围观
        </span>
        <!--  <introduce-tool-tip :content="roomInfo.introduce" />-->
      </div>
    </div>
    <div
      class="live-info"
    >
      <ul class="switch-container">
        <li
          class="item"
          :class="{'active': adminTab === 'info'}"
          @click="swtichTab('info')"
        >
          直播详情
        </li>
        <li
          v-if="projectAlbumShow"
          class="item"
          :class="{'active': adminTab === 'projectAlbum'}"
          @click="swtichTab('projectAlbum')"
        >
          项目集
        </li>
        <li
          v-if="roomInfo.dots && roomInfo.dots.length"
          class="item"
          :class="{'active': adminTab === 'liveDot'}"
          @click="swtichTab('liveDot')"
        >
          精彩节点
        </li>
      </ul>
      <div>
        <!-- 项目集/资料夹 -->
        <project-album v-if="adminTab === 'projectAlbum'" />
        <scroll-bar
          v-if="adminTab === 'info'"
          class="detail-body main-scroll"
          :flex-layout="true"
        >
          <div v-html="handleIntroduce(roomInfo.introduce)" />
        </scroll-bar>
        <scroll-bar
          v-if="adminTab === 'liveDot'"
          class="detail-body main-scroll"
          :flex-layout="true"
        >
          <live-dot-timeline
            :dots="roomInfo.resourceConvertStatus === 1 ? []: roomInfo.dots"
            :curr-dot="progressTime"
            @click="handleTimeLineClick"
          />
        </scroll-bar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import LiveMaskBroadCast from '@/views/liveRoom/components/LiveMaskBroadCast';
import ScrollBar from '@/components/ScrollBar';
import { getProjectAlbum } from '@/services/room';
import ProjectAlbum from '@/views/components/ProjectAlbum';
import LiveDotTimeline from './LiveDotTimeline';
import Slider from './Slider';

export default {
  name: 'VideoReplay',
  components: {
    LiveMaskBroadCast,
    ScrollBar,
    LiveDotTimeline,
    Slider,
    ProjectAlbum,
    Share: () => import('@/views/components/Share'),
    Accusal: () => import('@/views/components/AccusalLive'),
  },
  filters: {
    handleDuration(mval) {
      let seconds = (mval / 1000).toFixed(0);
      const hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : `0${Math.floor(seconds / 3600)}`;
      seconds -= 3600 * hour;
      const min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : `0${Math.floor(seconds / 60)}`;
      seconds -= 60 * min;
      const sec = seconds >= 10 ? seconds.toFixed(0) : `0${seconds.toFixed(0)}`;
      return `${hour}:${min}:${sec}`;
    },
    filterTime(time) {
      return moment(time).format('YYYY-MM-DD');
    },
    filterLiveType(type) {
      switch (type) {
      case 0:
        return '分享会';
      case 1:
        return '个人路演';
      case 2:
        return '路演活动';
      default:
        return '分享会';
      }
    },
  },
  props: {
    roomInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
      roomId: this.$route.params.id,
      liveLink: window.location.href,
      initPlay: true,
      appIdentifier: this.$config.APPIDENTIFIER,

      roomToken: '',

      player: null,
      phase: '',
      audio: null,
      playUrl: null,
      progress: 0, // 进度比例
      duration: 0, // 总时长
      progressTime: 0,
      volumeProgress: 50,
      handleRate: true,
      volumeShow: false,
      phaseBtn: 'pause',

      projectAlbumShow: true,
      adminTab: 'info',
    };
  },
  computed: {
    ...mapState('user', ['isLogin', 'profile']),
  },
  watch: {
    progressTime(val) {
      // 进度时间
      this.progress = (val / this.duration) * 100;
      if (this.progress === 100) {
        this.handleStop();
      }
    },
    volumeProgress(val) {
      const videoDom = this.$refs.rePlayer;
      videoDom.volume = val / 100;
    },
    duration(val) {
      if (val) {
        this.loading = false;
      }
    },
    roomInfo(val) {
      if (val.publishStatus === 1 && val.mp4RecordUrl) {
        this.playUrl = val.mp4RecordUrl?.replace(/^http:\/\//, 'https://');
      } else {
        this.playUrl = val.mp3VideoUrl?.replace(/^http:\/\//, 'https://');
      }
    },
  },
  async mounted() {
    await this.getProjectAlbum();
  },
  beforeDestroy() {
    const playerDom = document.querySelector('.overall-box');
    if (playerDom && playerDom.length) {
      playerDom.removeEventListener('mouseenter', this.setStyleFlex);
      playerDom.removeEventListener('mouseleave', this.setStyleNone);
    }
  },
  methods: {
    ...mapActions('login', ['showLoginDialog']),
    // 获取白板、音频、云信令token
    addlisteners() {
      this.$refs.rePlayer.ontimeupdate = this._currentTime;
      // this.$refs.player.addEventListener('timeupdate', this._currentTime);
    },
    async getDuration() {
      this.duration = +this.$refs.rePlayer.duration.toFixed(0) * 1000;
      this.audio = this.$refs.rePlayer;
      // this.handlePlay();
      this.addlisteners();
      await this.loadPlayer();
    },
    async loadPlayer() {
      const playerDom = document.querySelector('.replay-play');
      playerDom.addEventListener('mouseenter', this.setStyleFlex);
      playerDom.addEventListener('mouseleave', this.setStyleNone);
      const videoDom = this.$refs.rePlayer;
      videoDom.volume = this.volumeProgress / 100;
      this.handleRate = false;
    },

    async handlePlay() {
      if (!this.duration) {
        return;
      }
      if (this.progress === 100) {
        this.progress = 0;
        this.audio.currentTime = 0;
      }
      this.audio.play();
      this.phase = 'playing';
      this.initPlay = false;
    },
    handleStop() {
      this.handleRate = false;
      this.audio.pause();
      this.phase = 'pause';
    },
    handlePlayerStyle(val) {
      // 音量打开且鼠标在别的区域点击关闭音量popover后，播放器条仍显示。
      this.volumeShow = (val === 'show');
    },
    setStyleFlex() {
      const arrows = document.querySelectorAll('.player-content');
      arrows.forEach((itm) => {
        // itm.style.display = 'flex';
        itm.style['animation-name'] = 'replayPlayControlShow';
      });
    },
    setStyleNone() {
      if (this.volumeShow) return;
      const arrows = document.querySelectorAll('.player-content');
      arrows.forEach((itm) => {
        // itm.style.display = 'none';
        itm.style['animation-name'] = 'replayPlayControlHide';
      });
    },

    async handleProgress(val) {
      // this.handleRate = true;
      this.$refs.rePlayer.currentTime = (val * this.duration) / 100 / 1000;
      // this.handleRate = false;
    },

    // 切换右侧tab
    swtichTab(val) {
      this.adminTab = val;
    },
    handleIntroduce(val) {
      if (!val) return;
      return val.replace(/\n/g, '<br>');
    },
    async getProjectAlbum() {
      try {
        const result = await getProjectAlbum(this.roomId);
        this.projectAlbum = result.proList;
        this.projectAlbumShow = !!this.projectAlbum.length;
      } catch (error) {
        // console.log(error)
      }
    },
    async handleTimeLineClick(time) {
      this.$refs.rePlayer.currentTime = time / 1000;
    },
    _currentTime() {
      if (this.$refs.rePlayer) {
        this.progressTime = parseInt(this.$refs.rePlayer.currentTime, 10) * 1000;
      }
    },
    async handleMovEnd(v) {
      const { val, type } = v;
      if (this.timeLineLoading || type === 'click') return;
      this.timeLineLoading = true;
      this.$refs.rePlayer.currentTime = (parseInt(val, 10) * this.duration) / 100 / 1000;
      this.timeLineLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import './../index';
.white-player {
  width: 800px !important;
  height: 450px !important;
  position: absolute;
  background: rgba(0, 0, 0, 0.01) !important;
  z-index: 201;
}
.player-mask {
  width: 800px !important;
}
</style>
<style lang="scss">
@import './../globeIndex';
</style>
