<template>
  <el-timeline>
    <el-timeline-item
      v-for="(dot, index) in dots"
      :key="index"
      :timestamp="handleDuration(dot.momentPoint)"
      placement="top"
      :class="[currDot >= dot.momentPoint
        && (index<dots.length-1 ? currDot< dots[index+1].momentPoint : true)
        ? 'current-dot': '']"
      @click.native="handleClick(dot.momentPoint)"
    >
      {{ dot.momentContent }}
    </el-timeline-item>
  </el-timeline>
</template>

<script>
export default {
  name: 'LiveDotTimeline',
  props: {
    dots: {
      type: Array,
      default: () => [],
    },
    currDot: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleDuration(mval) {
      let seconds = (mval / 1000).toFixed(0);
      const hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : `0${Math.floor(seconds / 3600)}`;
      seconds -= 3600 * hour;
      const min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : `0${Math.floor(seconds / 60)}`;
      seconds -= 60 * min;
      const sec = seconds >= 10 ? seconds.toFixed(0) : `0${seconds.toFixed(0)}`;
      return `${hour}:${min}:${sec}`;
    },
    handleClick(time) {
      this.$emit('click', time);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ {
  .el-timeline-item__content {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(255,255,255,0.90);
    letter-spacing: 0;
    line-height: 20px;
  }

  .el-timeline-item__timestamp {
    opacity: 0.6;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 20px;
  }

  .el-timeline-item__node {
    left: 1px;
    width: 8px;
    height: 8px;
    border: 1px solid #151A28;
  }

  .el-timeline-item:hover {
    cursor: pointer;

    .el-timeline-item__content {
      color: #5a9aff;
    }

    .el-timeline-item__node {
      background: #5a9aff;
    }
  }

  .el-timeline-item__tail {
    border-left: 2px solid #9699ac;
  }

  .current-dot {
    .el-timeline-item__content {
      color: #5a9aff;
    }

    .el-timeline-item__node {
      background: #5a9aff;
    }
  }
}
</style>
