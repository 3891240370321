<template>
  <div class="live-mask-broadcast-wrapper">
    <div class="mask-wapper">
      <img src="@/assets/img/pic@2x.png">
      <div
        v-if="fromView==='live' && !liveExited"
        class="label-wrapper"
      >
        <img
          class="live-gif"
          src="@/assets/img/LarkLive.gif"
        >
        直播中
      </div>
      <div class="mask-content-wrapper">
        <div v-if="liveExited">
          <p class="mask-title">
            直播已结束
          </p>
          <p class="mask-content">
            感谢您的观看，快去观看更多精彩直播吧~
          </p>
          <el-button
            type="primary"
            class="apply-live-btn"
            @click="backToHome"
          >
            返回首页
          </el-button>
        </div>
        <template v-else>
          <div v-if="refuseCode === 2 || refuseCode === 3">
            <p class="mask-title">
              本场直播为邀请制
            </p>
            <p class="mask-content">
              您未在受邀名单内，可向本场直播管理员申请观看
            </p>
            <el-button
              v-sensor:click="{
                $element_id: 'live_application',
                consumer_id: profile.uid
              }"
              type="primary"
              class="apply-live-btn"
              :class="{
                isApplyedLiveBtn: refuseCode === 3,
              }"
              @click="handleWatch"
            >
              {{ refuseCode === 2 && !isApply ? '申请观看' : '已申请' }}
            </el-button>
          </div>
          <div v-else-if="refuseCode === 6">
            <p class="mask-title">
              {{ refuseReason }}
            </p>
            <p class="mask-content">
              请您去完成相关身份认证
            </p>
            <el-button
              type="primary"
              class="apply-live-btn"
              @click="handleCertify"
            >
              去认证
            </el-button>
          </div>
          <div v-else-if="refuseCode === 0">
            <p class="mask-content">
              您还未登录，登录后查看精彩直播
            </p>
            <el-button
              type="primary"
              class="apply-live-btn"
              @click="handleClickLogin"
            >
              登录
            </el-button>
          </div>
          <div v-else-if="refuseCode === 14">
            <p class="mask-title">
              {{ refuseReason }}
            </p>
            <el-button
              type="primary"
              class="live-list"
              @click="backToHome"
            >
              返回首页
            </el-button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { applyLive } from '@/services/room';
import { environmentHref, cloudRequestPath } from '@/utils/cloudRequest';
import { getCloudToken } from '@/services/home';

export default {
  props: {
    refuseCode: {
      type: Number,
      default: 100,
    },
    // 拒绝进入房间的理由
    refuseReason: {
      type: String,
      default: '',
    },
    roomId: {
      type: [String, Number],
      default: '',
    },
    liveExited: {
      type: Boolean,
      default: false,
    },
    fromView: {
      type: String,
      default: 'live',
    },
  },
  data() {
    return {
      isApply: false,
    };
  },
  computed: {
    ...mapState('user', ['profile']),

  },
  methods: {
    ...mapActions('login', ['showLoginDialog']),
    /**
     * NOLOGIN(0, "该用户未登录"),
     * NOAPPLY(2, "本场直播为邀请制，您未在受邀名单内，可向本场直播管理员申请观看"),
     * PENDINGAPPLY(3, "本场直播为邀请制，您未在受邀名单内，可向本场直播管理员申请观看"),
     * NOROLE(6, "身份不符合要求"),   具体文案我会返回
    */
    async handleWatch() {
      if (this.refuseCode === '3') return;
      try {
        const res = await applyLive({
          id: this.roomId,
        });
        if (res?.code === 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success(res);
        this.isApply = true;
      } catch (error) {
        // console.log(error)
      }
    },
    // 去个人版认证身份
    async handleCertify() {
      try {
        const token = await getCloudToken();
        const fullPath = `${environmentHref}/#/financing/personalCenter`;
        window.open(`${cloudRequestPath}&token=${token}&redirectUrl=${fullPath}`);
      } catch (error) {
        // console.log(error)
      }
    },
    // 返回首页
    backToHome() {
      this.$router.push('/home');
    },
    // 登录
    handleClickLogin() {
      this.showLoginDialog();
    },
  },
};
</script>
<style lang="scss" scoped>
.live-mask-broadcast-wrapper {
  width: 1100px;
  height: 500px;

  .mask-wapper {
    width: 100%;
    height: 100%;
    position: relative;

    > img {
      width: 100%;
      height: 100%;
    }

    .label-wrapper {
      position: absolute;
      height: 18px;
      line-height: 18px;
      background: rgba(255, 67, 67, 0.8);
      border-radius: 2px;
      left: 16px;
      top: 16px;
      color: #fff;
      font-size: 12px;
      font-family: PingFangSC-Semibold;
      display: flex;
      padding: 0 4px;
      align-items: center;

      .live-gif {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
    }
  }

  .mask-content-wrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 162px;

    .mask-title {
      opacity: 0.9;
      font-weight: 700;
      font-size: 22px;
      color: #fcfcfc;
      line-height: 26px;
      margin-bottom: 24px;
    }

    .mask-content {
      opacity: 0.9;
      font-size: 18px;
      color: #fcfcfc;
      line-height: 26px;
      margin-bottom: 60px;
    }

    .apply-live-btn {
      width: 152px;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      letter-spacing: 2px;
      text-align: center;
      line-height: 20px;

      &.isApplyedLiveBtn {
        background-color: #7c7d81;
        border-color: #7c7d81;
      }
    }
  }
}
</style>
