<template>
  <scroll-bar
    v-loading="loading"
    class="body-wrapper main-scroll"
    :flex-layout="true"
  >
    <div
      v-for="(item, index) in projectAlbum"
      :key="index"
      class="project-item"
    >
      <div class="project-content">
        <img
          v-if="item.companyLogo"
          class="project-img"
          :src="item.companyLogo"
          @click="goProject(item.companyId)"
        >
        <span
          v-else
          class="project-img main-top-info-avator-img"
          :style="{
            background: getRandomColor(item.companyId)
          }"
          @click="goProject(item.companyId)"
        >{{ item.companyName[0] }}</span>

        <div class="project-info">
          <div
            class="project-name"
            @click="goProject(item.companyId)"
          >
            {{ item.companyName }}
            <span
              v-if="item.existBP"
              class="project-bp"
            >BP</span>
          </div>
          <div
            class="project-type"
            :class="{'long-brief': !isAdmin}"
          >
            {{ item.brief }}
          </div>
          <div
            v-if="item.tagNames"
            class="tag-content"
          >
            <span class="project-tag">
              #{{ item.tagNames }}
            </span>
          </div>
        </div>
      </div>

      <!-- 仅直播间管理员有操作ppt权限 -->
      <!-- playerStatus 1正在播放 -->
      <div
        v-if="isAdmin && item.existBP && !isLiveStream"
        class="play-btn"
        :class="item.playerStatus ? 'stop' : 'play'"
        @click="playPpt(item.companyId, item.playerStatus)"
      >
        {{ item.playerStatus ? '停止播放' : '立即播放' }}
      </div>
    </div>
    <!-- 文稿 -->
    <template v-if="isAdmin">
      <div
        v-for="(item, index) in resourceList"
        :key="`pro${index}`"
        class="project-item"
      >
        <div class="project-content">
          <span
            class="project-img main-top-info-avator-img"
            :style="{
              background: getRandomColor(item.id)
            }"
          >{{ item.resourceName[0] }}</span>
          <div class="project-info">
            <div class="project-name resource-name">
              {{ item.resourceName }}
            </div>
          </div>
        </div>

        <!-- 仅直播间管理员有操作ppt权限 -->
        <!-- playerStatus 1正在播放 -->
        <div
          v-if="!isLiveStream"
          class="play-btn"
          :class="item.playerStatus ? 'stop' : 'play'"
          @click="playRes(item.id, item.playerStatus)"
        >
          {{ item.playerStatus ? '停止播放' : '立即播放' }}
        </div>
      </div>
    </template>
  </scroll-bar>
</template>
<script>
import { mapGetters } from 'vuex';
import { getProjectAlbum, getProjectPptInfo } from '@/services/room';
import { environmentHref, cloudRequestPath } from '@/utils/cloudRequest';
import { getCloudToken } from '@/services/home';
import ScrollBar from '@/components/ScrollBar';
import logoColors from '@/utils/logoColors';

export default {
  name: 'ProjectAlbum',
  components: { ScrollBar },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      roomId: this.$route.params.id,
      loading: false,
      projectAlbum: [],
      resourceList: [],
      logoColors,
    };
  },
  computed: {
    ...mapGetters('room', ['isLiveStream']),
  },
  mounted() {
    this.getProjectAlbum();
  },
  methods: {
    // 获取项目集列表
    async getProjectAlbum() {
      try {
        const result = await getProjectAlbum(this.roomId);
        this.projectAlbum = result.proList;
        this.resourceList = result.resourceVoList;
      } catch (error) {
        // console.log(error)
      }
    },
    // 播放文稿
    async playRes(resourceId, playerStatus) {
      try {
        // 获取ppt列表
        const result = await getProjectPptInfo({
          roomId: Number(this.roomId),
          resourceId,
          playerStatus: playerStatus === 1 ? 0 : 1,
        }); // 项目id
        this.getProjectAlbum();

        if (playerStatus === 1) { // playerStatus 1:正在播放
          this.$emit('stop-play-ppt');
        } else {
          this.$emit('play-ppt', result, resourceId);
        }
      } catch (error) {
        // console.log(error)
      }
    },
    // 播放ppt
    async playPpt(projectId, playerStatus) {
      try {
        // 获取ppt列表
        const pptList = await getProjectPptInfo({
          roomId: Number(this.roomId),
          projectId,
          playerStatus: playerStatus === 1 ? 0 : 1,
        }); // 项目id
        this.getProjectAlbum();

        if (playerStatus === 1) { // playerStatus 1:正在播放
          this.$emit('stop-play-ppt');
        } else {
          this.$emit('play-ppt', pptList, projectId);
        }
      } catch (error) {
        // console.log(error)
      }
    },
    // 跳转个人版项目详情页
    async goProject(projectId) {
      try {
        const token = await getCloudToken();
        const fullPath = `${environmentHref}/#/insight/InsightProject/${projectId}`;
        window.open(`${cloudRequestPath}&token=${token}&redirectUrl=${fullPath}`, 'cloud');
        if (token) {
          this.$emit('handle-click-project');
        }
      } catch (error) {
        // console.log(error)
      }
    },
    // 随机颜色
    getRandomColor(id) {
      // 随机生成十六进制颜色
      let randomIndex = '';
      if (id) {
        const idStr = id.toString();
        randomIndex = idStr.substring(idStr.length - 1);
      } else {
        randomIndex = Math.floor(Math.random() * this.logoColors.length);
      }
      return this.logoColors[randomIndex];
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin round-img {
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
}

.body-wrapper {
  max-height: 426px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 16px;
  padding-right: 14px;
}

.project-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.project-content {
  display: flex;

  .main-top-info-avator-img {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    object-fit: cover;

    @include round-img();
  }
}

.project-img {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  // border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  object-fit: contain;
}

.project-name {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 20px;
  cursor: pointer;
  max-width: 152px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: #fff;
  }

  &.resource-name {
    line-height: 40px;
  }

  .project-bp {
    display: inline-block;
    background: #3e74ca;
    border-radius: 2px;
    padding: 0 4px;
    margin-left: 4px;
    font-size: 11px;
    color: #fff;
    text-align: justify;
    line-height: 16px;
  }
}

.project-type {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  line-height: 18px;
  margin-top: 3px;
  width: 152px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.long-brief {
    width: 219px;
  }
}

.tag-content {
  margin-top: 4px;
}

.project-tag {
  line-height: 18px;
  display: inline-block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
}

.play-btn {
  width: 68px;
  height: 28px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  margin-top: 6px;

  &.play {
    background: #3e74ca;
  }

  &.stop {
    background: #fa6e6e;
  }
}

</style>
