// 图片压缩
/**
 * @param {url, width} identityRoles
 * @returns url 压缩后的图片地址
 * 根据url判断图片属于又拍云格式或阿里云格式
 * 根据格式不同编写不同的压缩指令
 * 返回对应的压缩地图片地址
 * 阿里云: https://jingdatat.oss-cn-beijing.aliyuncs.com/dealmatetest/2021728-174035_1644994823868.png
 * 又拍云: https://pic.jingdata.com/avatar/202105/12062817/6rf67fz7hdcbr763.jpg!480
 */
export function imgCompression(url, width = 1080) {
  // const pic = RegExp(/pic.jingdata.com/);
  const oss = RegExp(/jingdatat.oss-cn-beijing.aliyuncs.com/);
  // if (pic.exec(url)) {
  //   return `${url}!/fw/${width}`;
  // }
  if (oss.exec(url)) {
    return `${url}?x-oss-process=image/resize,w_${width}`;
    // return `${url}?x-oss-process=image/quality,q_50`;
  }
  return url;
}
