<template>
  <div
    ref="forRate"
    class="rate-group"
  >
    <div
      class="rate-runway"
      @click="onRateClick"
      @mousedown="onRateMove"
    >
      <div
        ref="RateBar"
        class="rate-bar"
        :style="barStyle"
      />
      <div
        ref="RateWrapper"
        class="rate-wrapper"
        :style="wrapperStyle"
        @click="handleClickRateWrapper"
      >
        <div
          ref="RateBtn"
          class="rate-button"
        />
      </div>
      <div
        v-if="showDot"
        class="rate-dots"
      >
        <template v-for="(dot, index) in dots">
          <el-tooltip
            :key="index"
            :content="dot.momentContent"
            effect="dark"
            placement="top"
            :visible-arrow="false"
            popper-class="dots-tooltip"
          >
            <div
              :style="{ left: getDotPosition(dot.momentPoint, index),}"
              class="rate-dot"
              @click="handleDot(dot.rate)"
            />
          </el-tooltip>
        </template>
      </div>

      <!--        <div-->
      <!--            ref="RateBtn"-->
      <!--            class="rate-button"-->
      <!--        />-->
    </div>
  </div>
</template>

<script>
const runWayLen = 510;
export default {
  name: 'Slider',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    dots: {
      type: Array,
      default: () => [],
    },
    showDot: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: [Number, Date],
      default: 0,
    },
  },
  data() {
    return {
      rate: (this.value * runWayLen) / 100,
      type: 'click',
    };
  },
  computed: {
    barSize() {
      // return `${this.value}%`;
      return this.ratePren;
    },
    barStyle() {
      return { width: this.barSize };
    },
    wrapperStyle() {
      return { left: this.ratePren };
      // return { left: `${this.value}%` };
      // return { left: `${this.value - (5 / runWayLen) * 100}%` };
    },
    ratePren() {
      return `${(this.rate / runWayLen) * 100}%`;
    },
  },
  watch: {
    value(val) {
      this.rate = (val * runWayLen) / 100; // 进度百分比*总长 / 100
      // const valLeft = val - (5 / 510) * 100;
      // this.$refs.RateBar.style.width = `${val}%`;
      // this.$refs.RateBtn.style.left = `${valLeft}%`;
    },
  },
  methods: {
    onRateClick(event) {
      event.stopPropagation();
      const rate = (event.offsetX / runWayLen) * 100;
      this.$refs.RateBar.style.width = `${rate}%`;
      this.$refs.RateWrapper.style.left = `${rate}%`;
      this.$emit('change', rate);
    },
    handleClickRateWrapper() {
      // 点击此处，偏移量小，进度点回退：缩小icon可点击范围，阻止事件
      event.stopPropagation();
    },
    onRateMove() {
      let down = true;
      this.type = 'click';
      this.$emit('rateMoving');
      this.$refs.forRate.onmousemove = ((move) => {
        this.type = 'move';
        if (!down) {
          return;
        }
        this.rate += move.movementX;
        // this.rate += move.movementX;
        if (this.rate >= runWayLen) {
          this.rate = runWayLen;
        }
        if (this.rate <= 0) {
          this.rate = 0;
        }
        this.$refs.RateBar.style.width = this.ratePren;
        this.$refs.RateWrapper.style.left = this.ratePren;
      });
      document.onmouseup = (() => {
        if (down) {
          this.$refs.RateBar.style.width = this.ratePren;
          this.$refs.RateWrapper.style.left = this.ratePren;
          this.$emit('rateMovEnd', { val: this.ratePren, type: this.type });
        }
        down = false;
      });
    },
    handleDot(rate) {
      event.stopPropagation();
      this.$refs.RateBar.style.width = `${rate}%`;
      this.$refs.RateWrapper.style.left = `${rate}%`;
      this.$emit('change', rate);
    },
    getDotPosition(val, index) {
      const rate = (val / this.duration) * 100;
      this.dots[index].rate = (val / this.duration) * 100;
      return `${rate}%`;
    },
  },
};
</script>

<style scoped lang="scss">
.rate-group {
}
.rate-runway {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  margin: 9px 0;
  width: 100%;
  height: 4px;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
}
.rate-bar {
  background-color: #FFF;
  width: 0;
  height: 4px;
  border-radius: 3px;
  position: absolute;
}
.rate-wrapper {
  //height: 36px;
  //width: 36px;
  height: 10px;
  width: 10px;
  top: -3px;
  z-index: 1001;
  //top: -15px;
  //padding: 13px;
  transform: translateX(-50%);
  background-color: transparent;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: normal;
  position: absolute;
}
.rate-button {
  width: 10px;
  height: 10px;
  border: 0;
  position: absolute;
  background-color: #FFF;
  border-radius: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
//.rate-button {
//  width: 10px;
//  height: 10px;
//  border: 0;
//  top: -2px;
//  left: -5px;
//  position: absolute;
//  background-color: #FFF;
//  border-radius: 50%;
//  -webkit-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//}
.rate-dot {
  height: 4px;
  width: 6px;
  background: #fff;
  position: absolute;
  transform: translateX(-50%);
  border-left: 0.1px solid rgba(21,26,40,0.60);
  border-right: 0.1px solid rgba(21,26,40,0.60);
}
</style>
<style lang="scss">
.dots-tooltip{
  max-height: 52px;
  max-width: 152px;
  padding: 8px 16px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  background: rgba(21,26,40,0.70) !important;
  border-radius: 4px;
  transform: translate(0, -5px);
}
</style>
